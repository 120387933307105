<template>
    <div class="container">
        <div class="header">
            <div class="title">SHOPPING BAG</div>
            <div id="msg" class="desc">Your shopping bag is empty.</div>
        </div>      
        <div id="all">
            <div class="order-container">
                <div id="product-list" class="product-list" >
                    <div :id="product.productId" class="product" v-for="product in products" :key="product.productId">
                        <div class="item">
                            <img class="thumbnail" :src="product.image" alt="">
                            <div class="info">
                                <router-link :to="product.link" class="title">{{product.name}} · {{product.option}}</router-link>
                                <div class="item-desc">{{product.desc}}</div>
                                <div class="price">£{{product.price}}</div>
                                <div class="quantity">
                                    <div class="label">Quantity</div>
                                    <div class="drop">
                                        <select :id=" 'select_' + product.productId" @change="updateTotal(product.productId)" name="quantity">
                                            <option value="${element.quantity}" selected disabled hidden>{{product.quantity}}</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div @click="removeFromBag(product.productId)" class="remove">
                                <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2.70996 5.51611V26.2903C2.70996 27.7868 3.92312 29 5.41964 29H19.8713C21.3678 29 22.5809 27.7868 22.5809 26.2903V5.51611" stroke="#777777" stroke-width="1.66384"/>
                                    <path d="M18.0643 5.51613V2.33107C18.0643 1.59594 17.4684 1 16.7332 1H8.55666C7.82153 1 7.22559 1.59594 7.22559 2.33107V5.51613" stroke="#777777" stroke-width="1.66384"/>
                                    <path d="M0 5.51611L25.2903 5.51612" stroke="#777777" stroke-width="1.66384"/>
                                    <path d="M9.93555 10.0322L9.93555 23.5806" stroke="#777777" stroke-width="1.66384"/>
                                    <path d="M15.3545 10.0322L15.3545 23.5806" stroke="#777777" stroke-width="1.66384"/>
                                </svg>
                            </div>                             
                        </div>
                    </div>
                </div>
                
                <div class="order-summary">
                    <div class="header">Order summary</div>
                    <div class="sub-total">
                        <div id="sub-count" class="name">{{ this.itemCount }} Items Subtotal</div>
                        <div id="sub-price" class="price">£ {{ parseFloat(this.total).toFixed(2) }}</div>
                    </div>
                    <div v-if="promoSuccess" class="sub-total">
                        <div class="name">{{ promoResult }}</div>
                        <div class="price">-£{{ disocuntAmount }}</div>
                    </div>
                    
                    <div class="delivery-options">
                        <input type="radio" id="standard" name="delivery"  @change="updateDelivery(0)"  value="0" >
                        <label for="standard" class="option">
                            <div class="name">Standard delivery</div>
                            <div id="standard-price" class="price">£3.50</div>
                        </label>
                        <input type="radio" id="nextday" name="delivery" @change="updateDelivery(1)" value="1">
                        <label for="nextday" class="option">
                            <div class="name">Next Day delivery</div>
                            <div class="price">£8.00</div>
                        </label>
                    </div>
                    <div class="gift-wrap">
                        <div class="header">Gift Wrapping</div>
                        <div class="collapse">
                            <input type="checkbox" id="together"   @change="updateGiftOption(1)"  value="1" name="gift">
                            <label for="together" class="option">
                                <div class="name">Gift wrap items together</div>
                                <div class="price">£3.50</div>
                            </label>
                            <input type="checkbox" id="each"  @change="updateGiftOption(2)" value="2" name="gift">
                            <label for="each" class="option">
                                <div class="name">Gift wrap per item</div>
                                <div class="price">£1.00 per item</div>
                            </label>
                        </div>
                    </div>
                    <br><br>
                    <div class="promo">
                        <div class="header">Promotional code</div>
                        <div style="display: flex; padding-top: 15pt">
                            <input type="text" placeholder="PROMO CODE" v-model="promo"> 
                            <button @click="applyPromo">Apply</button>
                        </div>
                        <label :class="[{ 'good': promoSuccess }, 'bad']">{{ promoResult }}</label>
                        
                    </div>
                    <div class="total">
                        <div class="label">Total (including delivery)</div>
                        <div id="grand-total" class="grand-total">£ {{ grandTotal.toFixed(2) || '-' }}</div>
                    </div>
                    <div id="buyButton" @click="toCheckout()" class="checkout-now disable">
                        Checkout now
                    </div>
                </div>
            </div>
            <div id="delivery" class="delivery">
                <div class="title">
                    <img class="icon" src="/Assets/how-to-icons/van.svg" alt="">
                        DELIVERY 
                </div>
                
                <div class="desc"></div>
                <div class="del">
                    <div class="name">Standard delivery  (2-4 working days)*</div>
                    <div class="price">£3.50</div>
                </div>
                <div class="del">
                    <div class="name">Next Day delivery  (1-2 working days)</div>
                    <div class="price">£8.00</div>
                </div>
                <div class="desc">*Complimentary delivery on all orders over £30</div>
            </div>
            <div id="returns" class="delivery">
                <div class="title">
                    <img class="icon" src="/Assets/how-to-icons/box.svg" alt="">
                    RETURNS
                </div>
                <div class="desc">
                    We do not offer any returns or exchange on any fresh produce.
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>



.bad {
    color: red;
}

.good {
    color: green;
}

.promo {
    
}

.promo button{
    width: 100pt;
    border-radius: 3pt;
    margin-left: 10pt;
    cursor: pointer;
    font-family: unset;
    font-weight: 600;
    background: rgb(240, 240, 240);
    color: rgb(26, 26, 26);

}

.promo input {
    border-radius: 3pt;
    text-transform: uppercase;
    font-size: 9pt;
}

.promo label {
    font-size: 10pt;
    padding: 2pt;
    line-height: 30pt !important;
}

.container
{
    max-width: 900pt;
    margin: auto;
    margin-top: 10pt;
    overflow: hidden;
}

#all
{
    padding: 10pt;
}

.header 
{
    padding: 20pt;
}

.title
{
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
}

.header .desc   
{
    padding-top: 15pt;
    width: 80%;
    max-width: 350pt;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
    color: rgb(86, 86, 86);
}

.product-list
{
    margin-top: 30pt;
    display: flex;
    flex-direction: column;
    transition: all ease-in-out 300ms;
}



#item
{
    text-align: left;
    min-width: 120pt;
}

.empty
{
    flex: 1;
}

.product
{
    display: flex;
    flex-direction: column;
    height: 100pt;
    margin: 7pt;
    padding: 5pt;
    padding-bottom: 25pt;
    border-top: 1pt solid rgb(238, 238, 238);
    padding-top:20pt;
    max-height: 100pt;
}

 .item
{
    display: flex;
    min-width: 120pt;
    flex: 1;
    font-family: 'Montserrat';
}

.product .thumbnail 
{
    margin-top: -10pt;
    width: 45pt;
    padding: 5pt;
}

.item .info
{
    flex: 1;
    margin: auto;
    margin-top: 0pt;
    margin-left: 7pt;
}

.info .option-label
{
    padding: 0;
    background: none;
    font-size: 0.9em;
    color: rgb(151, 151, 151);
}

.item .item-desc
{
    flex: 1;
    text-align: left;
    font-size: 0.8em;
    margin: auto;
    color: rgb(151, 151, 151);
    margin-top: 2pt;
}



.item .title
{
    text-align: left;
    font-size: 1.1em;
    font-weight: 600;
    margin: auto;
}

.item .price
{
    margin-top: 6pt;
    font-weight: 600;
    font-family: 'Inter';
}

.remove
{
    margin-top: 7pt;
    margin-left: auto;
    display: flex;
    cursor: pointer;
}

.remove svg
{
    height: 12pt;
}

 .quantity
    {
        display: flex;
        font-family: 'Montserrat';
        font-weight: 700;
        color:black;
        width: fit-content;
        margin-top: 5pt;
        margin-left: 0;
    }

    .quantity .label
    {
        font-size: 0.9em;
        letter-spacing: 0.03em;
        margin: auto;
        margin-right: 10pt;
    }

    .quantity .drop
    {
        margin-left: 0pt;
        margin: auto;
        cursor: pointer;
    }


    .quantity .drop select
    {
        cursor: pointer;
        min-width: 50pt;
        border: none;
        font-size: 0.9em;
        font-family: 'Montserrat';
        font-weight: 700;

        -moz-appearance: none; 
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;

        background: url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2L13.3294 13M24 2L11.6706 13' stroke='black' stroke-width='3'/%3E%3C/svg%3E%0A") no-repeat;
        background-position: right 11pt top 13pt;
        padding: 10pt;
        padding-left: 10pt;
        margin: auto;
    }



.order-summary
{
    margin-top: 15pt;
    background-color: #ffffff;
    padding: 15pt;
    display: flex;
    flex-direction: column;
    font-weight: 400;
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.048), 0 10px 10px -5px rgba(0, 0, 0, 0.021);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    border-radius: 4pt;
    border: 1pt solid rgb(241, 241, 241);
}

.order-summary .header
{
    letter-spacing: 0.015em;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 1.3em;
    padding: 0;
    padding-bottom: 10pt;
    border-bottom: 1pt solid rgb(236, 236, 236);
}

.order-summary .sub-total 
{
    display: flex;
    margin-top: 20pt;
}

.order-summary .price
{
    text-align: right;
    font-size: 0.8em;
}

@media only screen and (min-width: 750pt) 
{
   .order-container
   {
       display: flex;
       width: 100%;
   }
   .order-summary
   {
       margin-top: 20pt;
       margin-left: 20pt;
       min-width: 310pt;
       height: fit-content;
   }
   .product-list
   {
       flex: 1;
   }
}


.delivery-options
{
    margin-top: 13pt;
}

.option 
{
    display: flex;
    padding: 11pt;
    background-color: #FBFBFB;
    border: 2pt solid rgba(255, 255, 255, 0);
    cursor: pointer;
    border-radius: 2pt;
}

input
{
    visibility: hidden;
    height: 0pt;
}

input[type=text] {
    visibility: unset;
    height: unset;
    width: 100%;
    padding: 7pt;
    border: 1pt solid rgb(223, 223, 223);
    font-family: 'Montserrat';
}

.delivery-options > input:checked + label
{
    background-color: #EAF5E6;
    border: 2pt solid #9feeb1;
    color: #256133;
}
 .name
{
    flex:1;
    font-size: 0.8em;
}

.gift-wrap
{
    margin-top: 25pt;
}

.gift-wrap > input:checked + label
{
    background-color: #FAF0E1;
    border: 2pt solid #C29A5C;
    color: #2d1d04;
}

.total
{
    margin-top: 35pt;
    font-family: 'Montserrat';
    font-size: 1em;
    font-weight: 600;
    display: flex;
    transition: all ease-in-out 100ms;
}

.total .label
{
    flex: 1;
    padding-bottom: 20pt;
}

.checkout-now
{
    font-family: 'Montserrat';
    font-size: 1em;
    font-weight: 600;
    letter-spacing: 0.03em;
    background-color: #F2BA2A;
    padding: 11pt;
    text-align: center;
    cursor: pointer;
    border-radius: 3pt;
}

.checkout-now:hover
{
    filter: brightness(1.1);
}

.disable
{
    filter: grayscale(1);
}


.buy
{
    margin: auto;
    margin-top: 50pt;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    background-color: black;
    color: white;
    width: fit-content;
    padding: 10pt;   
}

.delivery
{
    margin-top: 20pt;
    border: 1.1pt solid rgb(235, 235, 235);
    padding: 15pt;
    padding-bottom: 15pt;
    max-width: 480pt;
    border-radius: 3pt;
}

.delivery .title
{
    display: flex;
    text-align: left;
    letter-spacing: 0.01em;
    padding-bottom: 1pt;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.85em;
    letter-spacing: 0.1em;
    color: #000000;
    flex: 1;
}

.delivery .desc
{
    font-size: 0.9em;
    margin-top: 10pt;
}

.del
{
    padding: 0pt;
    padding-bottom: 5pt;
    cursor: pointer;
}

.delivery .price
{
    font-size: 0.8em;
}

.delivery .del
{
    display: flex;
}

.delivery .icon
{
    margin: unset;
    margin-right: 7pt;
    width: 12pt;
    max-height: 14pt;
}

</style>


<script>

import axios from 'axios'

//const stripe = window.Stripe('pk_live_51I3meJAmKMmEAunddafuSaC6tX1otrs6iUufhV0RXmXehqlaN3vMYwxAbCZAF2sWnf1VanP141rv0sgLxSaBLDR4003sYjuMu3');
const stripe = window.Stripe('pk_live_51I3meJAmKMmEAunddafuSaC6tX1otrs6iUufhV0RXmXehqlaN3vMYwxAbCZAF2sWnf1VanP141rv0sgLxSaBLDR4003sYjuMu3')
export default {
  data: function () {
    return {
      total:0,
      deliverymethod: 0,
      deliverySelected: '',
      standard: 0,
      nextDay: 1,
      products: '',
      itemCount: 0,
      minDeliveryCost: 30,
      totalWithDelivery:'',
      giftOption:0,
      promo: '',
      promoResult: '',
      promoSuccess: false,
      discounts: []
    }
  },
  computed: {
      grandTotal() {
          var productsTotal = this.total + this.giftTotal - this.disocuntAmount;
          

          var deliveryTotal = 0
          if(this.deliverymethod == 0 && productsTotal < 30) {
              deliveryTotal += 3.50
          }
          else if(this.deliverymethod == 1) {
              deliveryTotal += 8.00
          }

          return productsTotal + deliveryTotal
      },
      giftTotal() {
          if(this.giftOption == 1) {
              return 3.50
          }
          else if(this.giftOption == 2) {
             return this.itemCount
          }

          return 0
      },
      disocuntAmount() { 
          if(this.promoSuccess) {
              return ((this.total + this.giftTotal) * 0.15).toFixed(2)
          }
          return 0
      }
  },
  methods: {
    applyPromo() {
        if(this.promo.toUpperCase() == 'RAMADAN15') {
            this.promoSuccess = true
            this.promoResult = '15% discount applied'
        } 
        else {
            this.promoSuccess = false
            this.promoResult = 'Invalid promo code'
        }
    },
    updateBag()
    {
        this.showDot();
        var products = [];
        this.itemCount = 0;
        if(localStorage.getItem('products')){
            products = JSON.parse(localStorage.getItem('products'));
        }

        document.querySelectorAll('.row').forEach(p => p.remove());


        this.total = 0
        products.forEach(element => {
            this.total += element.quantity * element.price;
            this.itemCount += parseInt(element.quantity);
        });

        this.products = products;

        if(this.total < this.minDeliveryCost)
        {
            document.getElementById('standard-price').innerHTML = '£3.50';
        }
        else
        {
            document.getElementById('standard-price').innerHTML = 'FREE';
        }
    },
    updateTotal(Id)
    {
        this.total = 0;
        this.itemCount = 0;
        var products = JSON.parse(localStorage.getItem('products'));
        var s = document.getElementById('select_' + Id);

        if(products.find(x => x.productId === Id))
        {
            var foundIndex = products.findIndex(x => x.productId == Id);
            var found = products.find(x => x.productId == Id);
            var updated = {'productId' : Id, name: found.name, option:found.option, desc: found.desc, link: '/product/' + Id, quantity : s.value, price: found.price};
            products[foundIndex] = updated;       
        }
        
        products.forEach(element => 
        {
            this.total += element.quantity * element.price;
            this.itemCount += parseInt(element.quantity);
        });

        if(this.total < this.minDeliveryCost)
        {
            document.getElementById('standard-price').innerHTML = '£3.50';
        }
        else
        {
            document.getElementById('standard-price').innerHTML = 'FREE';
        }

        localStorage.setItem('products', JSON.stringify(products));

    
        this.updateTitle();
    },
    removeFromBag(Id)
    {
        document.getElementById(Id).remove();
        var products = JSON.parse(localStorage.getItem('products'));
        var found = products.findIndex(x => x.productId == Id);

        products.splice(found, 1);
        localStorage.setItem('products', JSON.stringify(products));
        setTimeout(this.updateBag, 50);
        setTimeout(this.updateTotal, 50);
        this.showDot();
    },
    updateDelivery(value)
    {
        if(value != null)
        {
            this.deliverymethod = value;
        }


        if(this.deliverymethod == 0 && this.total < this.minDeliveryCost)
        {
            this.deliverySelected = true;
            document.getElementById('standard').checked = true;
        }
        else
        {
            this.deliverySelected = true;
            document.getElementById('standard').checked = true;
        }
        if(this.deliverymethod == 1)
        {
            this.deliverySelected = true;
            document.getElementById('nextday').checked = true;
        }


        
        if(this.deliverySelected)
        {
            if(this.deliverymethod != null)
            {
                document.getElementById('buyButton').classList.remove('disable');
            }
        }

    },
    updateGiftOption(value)
    {        
        if(this.giftOption == value)
        {
            this.giftOption = 0;
            
        }
        else
        {   
            if(value == 1)
            {
                document.getElementById('each').checked = false;
                this.giftOption = 1;
            }
            if(value == 2)
            {
                document.getElementById('together').checked = false;
                this.giftOption = 2;
            }
        }
    },
    async toCheckout()
    {
        if(this.deliverymethod == null)
        {
            return;
        }


        var products = [];
        if(localStorage.getItem('products')){
            products = JSON.parse(localStorage.getItem('products'));
        }

        this.lineItems = [];
        products.forEach(element => 
        {
            var lineItem = { price: element.productId, quantity: parseInt(element.quantity)};
            this.lineItems.push(lineItem);
        });


        //Add gift-wrap item
        if(this.giftOption == 1)
        {
            var giftWrapItem = { price: 'price_1IhGTDAmKMmEAundxHM4tGJ3', quantity: 1 };
            this.lineItems.push(giftWrapItem);
        }
        if(this.giftOption == 2)
        {
            giftWrapItem = { price: 'price_1Ie5QaAmKMmEAundQKzZNik6', quantity: this.itemCount };
            this.lineItems.push(giftWrapItem);
        }

        var shippingMethod = this.deliverymethod;

        if(this.deliverymethod == 0 && this.total > 30) {
            shippingMethod = 2
        }

        const payload = {
            lineItems: this.lineItems,
            shipping: shippingMethod
        }

        if(this.promoSuccess) {
            payload.discounts = [{
                coupon: 'ZePv3bUO',
            }]
        }



        await axios.post('https://tamrdate-stripe.herokuapp.com/payment', payload).then((response) => {
            var cs = response.data.id;
            stripe.redirectToCheckout({ sessionId: cs });
        })
        
    },
    showDot()
    {
        let products = [];

        if(localStorage.getItem('products'))
        {
            products = JSON.parse(localStorage.getItem('products'));
        }

        if (products.length > 0) 
        {
            document.getElementById('dot').classList.add('showdot');
            document.getElementById('all').style.display = 'block';
            document.getElementById('msg').style.display = 'none';
        }
        else
        {
            document.getElementById('dot').classList.remove('showdot');
            document.getElementById('all').style.display = 'none';
        }
    },
    updateTitle()
    {
        if(this.itemCount == 1)
        {
            document.title = "Tamr - Bag (" + this.itemCount + " item) ";
        }
        else if(this.itemCount > 1)
        {
            document.title = "Tamr - Bag (" + this.itemCount + " items) ";
        }
        else
        {
            document.title = "Tamr - Your Bag is empty"
        }
    }
  },
  mounted()
  {
      this.showDot();
      this.updateBag();
      this.updateTitle();
      this.updateDelivery();
      this.updateGiftOption();
  }
}
</script>