import { render } from "./Product.vue?vue&type=template&id=34494b58&scoped=true"
import script from "./Product.vue?vue&type=script&lang=js"
export * from "./Product.vue?vue&type=script&lang=js"

import "./Product.vue?vue&type=style&index=0&id=34494b58&scoped=true&lang=css"
import "./Product.vue?vue&type=style&index=1&id=34494b58&lang=css"
import "./Product.vue?vue&type=style&index=2&id=34494b58&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-34494b58"

export default script