<template>
    <div class="container">
        <div class="title">DELIVERY & RETURNS</div>
        <div id="delivery" class="delivery">
        <div class="title">
            <img class="icon" src="/Assets/how-to-icons/van.svg" alt="">
                DELIVERY 
        </div>
        
        <div class="desc"></div>
        <div class="del">
            <div class="name">Standard delivery  (2-4 working days)*</div>
            <div class="price">£3.50</div>
        </div>
        <div class="del">
            <div class="name">Next Day delivery  (1-2 working days)</div>
            <div class="price">£8.00</div>
        </div>
        <div class="desc">*Complimentary delivery on all orders over £30</div>
        </div>
        <div id="returns" class="delivery">
            <div class="title">
                <img class="icon" src="/Assets/how-to-icons/box.svg" alt="">
                RETURNS
            </div>
            <p>
                We do not offer any returns or exchange on any fresh produce.
            </p>
        </div>
    </div>
    
</template>

<style  scoped>

.container
{
    padding: 20pt;
    margin: auto;
    max-width: 900pt;
    width: 100%;
    box-sizing: border-box;
    margin-top: 0pt;
}

.title
{
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
}

.delivery
{
    margin: auto;
    margin-top: 20pt;
    border: 1.1pt solid rgb(235, 235, 235);
    padding: 15pt;
    padding-bottom: 15pt;
    border-radius: 3pt;
}

.delivery .title
{
    display: flex;
    text-align: left;
    letter-spacing: 0.01em;
    padding-bottom: 1pt;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 0.85em;
    letter-spacing: 0.1em;
    color: #000000;
    flex: 1;
}

.delivery .desc
{
    font-size: 8pt;
    margin-top: 10pt;
}

.del
{
    padding: 0pt;
    padding-bottom: 5pt;
}

 .name
{
    flex:1;
    font-size: 0.8em;
}

.delivery p {
    margin-top: 10pt;
    font-size: 0.8em;
    width: 90%;
    margin-bottom: 0;
}

.delivery .price
{
    font-size: 0.8em;
}

.delivery .del
{
    display: flex;
}

.delivery .icon
{
    margin: unset;
    margin-right: 7pt;
    width: 12pt;
    max-height: 14pt;
}

</style>