<template>
    <div class="container">  
        <div style="display:none" class="product-bar">
            <svg @click="$router.back()" width="15" height="28" viewBox="0 0 15 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 2L2 14.3294L13 26" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="title">
                <div class="name">{{ product.name }}</div>
                <div class="desc">{{ measure }}</div>
            </div>
            <svg class="like" @click="like=!like" v-bind:class="{ liked: like }"  width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.85251 16.1123L15.6466 25.653C15.8749 25.8755 16.1812 26 16.5 26C16.8188 26 17.1251 25.8755 17.3534 25.653L27.1475 16.1123C30.4216 12.9357 31.7025 9.82176 30.6279 6.56347C29.2774 2.46825 23.213 -0.509197 17.3555 5.04635C17.1246 5.26532 16.8182 5.39709 16.5 5.39709C16.1818 5.39709 15.8754 5.26533 15.6445 5.04635C9.78696 -0.509197 3.72263 2.46825 2.37207 6.56347C1.29752 9.82176 2.5784 12.9357 5.85251 16.1123Z"  stroke-linecap="square"/>
            </svg>
        </div>  
        <div class="top">
            <swiper
                class="swiper-container"
                :key="renderSwiper"
                :slides-per-view="1"
                :space-between="0"
                :pagination="{ clickable: true }">
                 <swiper-slide v-if="product.type == 'variety' && optionObj.value"> 
                    <img class="gallery-image swiper-slide" :src="'/Assets/product-previews/box-' + optionObj.value + '.png'">
                </swiper-slide>
                <swiper-slide 
                    class="swiper-wrapper" 
                    :key="image"
                    v-for="image in product.images">
                    <img class="gallery-image swiper-slide" loading=lazy :src="image">
                </swiper-slide>
                <swiper-slide v-if="product.id != 'variety' && optionObj.image"> 
                    <img class="gallery-image swiper-slide" :src="optionObj.image">
                </swiper-slide>
            </swiper>
            <div class="imp">
                <svg class="like fixed" @click="like=!like" v-bind:class="{ liked: like }"  width="33" height="28" viewBox="0 0 33 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.85251 16.1123L15.6466 25.653C15.8749 25.8755 16.1812 26 16.5 26C16.8188 26 17.1251 25.8755 17.3534 25.653L27.1475 16.1123C30.4216 12.9357 31.7025 9.82176 30.6279 6.56347C29.2774 2.46825 23.213 -0.509197 17.3555 5.04635C17.1246 5.26532 16.8182 5.39709 16.5 5.39709C16.1818 5.39709 15.8754 5.26533 15.6445 5.04635C9.78696 -0.509197 3.72263 2.46825 2.37207 6.56347C1.29752 9.82176 2.5784 12.9357 5.85251 16.1123Z"   stroke-linecap="square"/>
                </svg>
                <div class="info">
                    <div class="title">{{ product.name }}</div>
                    <div class="desc">{{ product.desc }}</div>
                    <div class="stock">
                        <div class="indicator" v-bind:style="stockLevel"></div>
                        <div class="msg">{{product.stock}}</div>
                    </div>
                </div>
                <div class="buy">
                    <div class="options">
                        <div class="o" v-for="o in product.options" :key="o.id">
                            <input :checked="o.value == initialOption" @change="optionObj = o" type="radio" v-model="option" :id="o.value" :value="o.value">
                            <label :for="o.value" class="option">
                                <div class="title">{{o.value}}</div>
                                <div class="price">£{{o.price}}</div>
                            </label>
                        </div>
                    </div>
                    <div class="quantity">
                        <div class="label">QUANTITY</div>
                        <div class="drop">
                            <select id="select_quantity"  @change="updateBagText()"  name="quantity">
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                    </div>
                    <button id="addbutton" @click="addToBag()" class="addtobag">
                        <div class="button-container">
                            <svg v-show="showLoader" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                            </svg>
                            <div class="bag-text">
                                {{bagText}}
                            </div>
                        </div>
                        
                    </button>
                </div>
            </div>
        </div>
        <div class="product">
            <div class="description">
                <div class="colli">
                        <div class="title">DESCRIPTION</div>
                    <div id="main-desc" class="collapsible-content open">
                        <div class="desc">
                            {{product.bodyDesc}}
                        </div>
                        <div class="qualities">
                            <ul>
                                <li :key="quality" v-for="quality in product.qualities">
                                    {{quality}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div v-if="product.notes" class="colli">
                    <div @click="toggleColli('notes')" class="collapsible-title ">
                        <div class="title">NOTES</div>
                        <div class="shape">+</div>
                    </div>
                    <div id="notes" class="collapsible-content open">   
                       <div class="note" :key="note" v-for="note in product.notes">
                            <div class="circle">
                                <img :src="note.image" alt="">
                            </div>
                            
                            <div class="name">{{note.name}}</div>
                       </div>
                    </div>
                </div>
                <div v-if="product.nutritional" class="colli">
                    <div @click="toggleColli('nutri')" class="collapsible-title">
                        <div class="title">NUTRITIONAL <br> INFORMATION</div>
                        <div class="shape">+</div>
                    </div>
                    <div id="nutri" class="collapsible-content">   
                        <br>
                        <table class="nutri">
                            <tr :key="ni" v-for="ni in product.nutritional">
                                <td class="name">{{ni.name}}</td>
                                <td class="value">{{ni.value}}</td>
                            </tr>
                            <tr v-if="product.nutritional">
                                <td style="border:none"></td>
                                <td class="value" style="border:none">*per 100g</td>
                            </tr>
                        </table>
                        <br>
                    </div>
                </div>
                <div class="title">CULTIVATION</div>
                    <inline-svg @loaded="updateMap($event)" :src="require('../../public/Assets/products/' + product.map + '.svg')"></inline-svg>
                    <div class="location">
                        {{ locationString }} 
                        <br>
                        {{ product.country }} <br> <br>
                    </div>
            </div>
        </div>
    </div>
</template>

<style scoped>



.checkmark__circle {
  stroke-dasharray: 266;
  stroke-dashoffset: 266;
  stroke-width: 3.5;
  stroke-miterlimit: 10;
  stroke: #ffffff;
  fill: none;
  animation: stroke 1.2s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    stroke-width: 3.5;
    stroke: #fff;
    stroke-miterlimit: 20;
    width: 30px;
    height: 30px;
    margin:auto;
    flex: 1;
    margin-left: -5px;
    margin-right: 15px;
    opacity: 0.6;
    padding: 1px;

}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.9s cubic-bezier(0.65, 0, 0.45, 1) 0.5s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes spin {
    100%
    {
        transform: rotate(200deg);
        stroke-dashoffset: 0;
    }
}

    .container
    {
        max-width: 1200pt;
        margin: auto;
        margin-top: 0pt;
        overflow:hidden;   
        
    }

    .top
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    @media only screen and (min-width: 1100px) {
        .container
        {
            margin: auto;
            margin-top: 100pt;
            width: 90%;
            }
        .top
        {

        }
        .imp
        {
            border-radius: 3pt;
            border-top: 1pt solid rgb(250, 250, 250);
            border-right: 1pt solid rgb(245, 245, 245);
            --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.041), 0 4px 6px -2px rgba(0, 0, 0, 0.014);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    } 


    .product-bar
    {
        display: flex;
        padding: 7pt;
        padding-left: 10pt;
        padding-right: 10pt;
        position: fixed;
        top: 36pt;
        z-index: 99;
        background: white;
        justify-content: center;
        width: 100%;
        max-width: 1400pt;
        box-sizing: border-box;
    }

    .product-bar svg
    {
        height: 15pt;
        width: 15pt;
        margin: auto;
        cursor: pointer;
    }

    .product-bar .title
    {
        text-align: center;
        flex: 1;
        margin: auto;
        align-self: center;
    }

    .like
    {
        stroke: black;
        transition: all ease-in-out 100ms;
    }

    .fixed
    {
        position: absolute;
        top: 0pt;
        right: 0;
        height: 14pt;
        padding-top: 15pt;
        padding-right: 12pt;
        padding-bottom: 20pt;
        padding-left: 20pt;
        cursor: pointer;
        z-index: 99;
        stroke: rgb(153, 153, 153);
        stroke-width: 1.8 !important;
    }

    .liked
    {
        fill: #eb3535;
        stroke: #eb3535;
        transition: all ease-in-out 100ms;
    }

    .title .name
    {
        font-family: 'Montserrat';
        font-size: 1.3em;
        font-weight: 600;
        letter-spacing: 0.07em;
        
    }

    .title .desc
    {
        font-size: 0.7em;
        color: #B3B3B3;
        text-transform: uppercase;
        font-weight: 500;
    }

    .imp
    {
        position: relative;
        padding: 20pt;
        flex: 1;
        max-width: 300pt;
        min-width: 240pt;
        display: flex;
        flex-direction: column;
        margin: auto;
    }


    .info
    {
        font-family: 'Montserrat';
    }

    .info .title
    {
        font-size: 1.4em;
        font-weight: 700;
        letter-spacing: 0.07em;
    }
    .info .desc
    {
        margin-top: 4pt;
        font-size: 0.9em;
        color: #B3B3B3;
    }

    .info .price   
    {
        margin-top: 9pt;
        font-size: 1em;
        font-weight: 600;
    }

    .info .stock
    {
        display: flex;
        margin-top: 5pt;
    }
    .indicator
    {
        width: 5pt;
        height: 5pt;
        background: rgb(240, 69, 69);
        border-radius: 3pt;
        margin: auto;
  
    }
    .info .stock .msg
    {
        margin: auto;
        flex: 1;
        margin-left: 5pt;
         font-size: 0.8em;
        color: #868686;
        font-size: 0.7em;
    }



    .buy
    {
        margin: auto;
        width: 100%;
    }
    
    input[type="radio"]
    {
        appearance: none;
        visibility: hidden;
    }

    .options
    {
        display: flex;
        justify-content: center;
        margin: auto;
        max-width: 260pt;
        padding-bottom: 25pt;
    }

    .o
    {
        flex: 1;
        margin: 7pt;
    }

    .option
    {
        
        padding: 10pt;
        border-radius: 4pt;
        background: #F6F6F6;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        border: 1.9pt solid #ffffff00;
        transition: ease-in-out all 50ms;
        min-width: 35pt;
        min-height: 46pt;
    }

    .option .title
    {
        font-family: 'Montserrat';
        text-align: center;
        color: #A5A5A5;
        font-weight: 400;
        font-size: 9.5pt;
        margin: auto;
        width: fit-content;
        cursor: pointer;
    }

    .option .price
    {
        font-weight: 500;
        font-size: 1.1em;
        text-align: center;
        margin: auto;
        padding-top: 7pt;
        padding-bottom: 3pt;
        cursor: pointer;
    }

    input[type="radio"]:checked + .option
    {
        background: white;
        border: 1.9pt solid #F0BF73;
        transition: ease-in-out all 50ms;
    }


    .quantity
    {
        display: flex;
        font-family: 'Montserrat';
        font-weight: 700;
        color:black;
        margin: auto;
        width: fit-content;
    }

    .quantity .label
    {
        font-size: 1em;
        letter-spacing: 0.07em;
        margin: auto;
        margin-right: 30pt;
    }

    .quantity .drop
    {
        margin-left: 0pt;
        margin: auto;
        cursor: pointer;
    }


    .quantity .drop select
    {
        cursor: pointer;
        min-width: 70pt;
        border: none;
        font-size: 1em;
        font-family: 'Montserrat';
        font-weight: 700;

        -moz-appearance: none; 
            -moz-appearance: none; 
            -webkit-appearance: none; 
            appearance: none;

        background: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 25 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 2L13.3294 13M24 2L11.6706 13' stroke='black' stroke-width='3'/%3E%3C/svg%3E%0A") no-repeat;
        background-position: right 11pt top 13pt;
        padding: 10pt;
        padding-left: 20pt;
        margin: auto;
    }

    .ops
    {
        padding: 10pt;
        flex: 1;
        font-family: 'Montserrat';
        font-size: 2em;
        text-align: center;
        color: #888888;
        cursor: pointer;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    #quantity
    {
        font-family: 'Montserrat';
        border:  none;
        text-align: center;
        flex: 1;
        max-width: 100pt;
        font-size: 1.5em;
    }

    .button-container
    {
        display: flex;
        margin: auto;
        transition: all ease-in-out 200ms;
    }

    .addtobag
    {
        display: flex;        
        height: 40pt;
        font-family: 'Montserrat';
        font-weight: 550;
        margin: auto;
        margin-top: 15pt;
        width: 100%;
        max-width: 600pt;
        font-size: 1em;
        letter-spacing: 0.07em;
        background-color: #F0BF73;
        color: white;
        transition: all ease-in-out 220ms ;
        cursor: pointer;
        border-radius: 2pt;
    }

    .bag-text
    {
        margin: auto;
        transition: all ease-in-out 200ms;
    }

    .addtobag:hover
    {
        filter: brightness(1.1);
    }

    #to-bag
    {
        font-family: 'Montserrat';
        text-align: center;
        color: grey;
        font-size: 1.2em;
        margin: auto;
    }

    .colli 
    {
        border-bottom: 1pt solid rgb(233, 233, 233);
    }

    .collapsible-title
    {
        display: flex;
        cursor: pointer;
        padding-top: 5pt;
        padding-bottom: 7pt;
    }

    .collapsible-title .shape
    {
        margin: auto;
        font-size: 2em;
        font-weight: 300;
        color: rgb(197, 197, 197);
        transition: all ease-in-out 200ms;
        line-height: 1em;
    }

    .collapsible-content 
    {
        visibility: hidden;
        max-height: 0;
        opacity: 0;
        padding: 0;
        transition: all ease-in-out 210ms;
    }

    .open
    {
        visibility: visible;
        max-height: 1200pt;
        opacity: 1;
        transition: all ease-in-out 210ms;
    }

    .close
    {
        margin-right: -2pt;
        transition: all ease-in-out 200ms;
        transform: translateX(3pt) rotate(45deg);
        
    }

    .description
    {
        padding: 20pt;
        padding-top: 10pt;
        max-width: 600pt;
        display: flex;
        flex-direction: column;
    }

    .description .title
    {
        font-family: 'Montserrat';
        font-size: 1em;
        font-weight: 700;
        color: #888888;
        letter-spacing: 0.1em;
        padding-bottom: 15pt;
        padding-top: 15pt;
        padding-left: 3pt;
        flex: 1;
    }

    .description .desc
    {
        width: 96%;
        max-width: 300pt;
        letter-spacing: -0.01em;
        color: #7E7E7E;
        line-height: 1.5em;
        font-weight: 300;
        font-style: italic;
        font-size: 0.94em;
    }

    .qualities 
    {
        margin-top: 25pt;
        list-style-type: square;
        width: 96%;
        max-width: 300pt;
        letter-spacing: -0.01em;
        color: #7E7E7E;
        line-height: 1.5em;
        font-weight: 300;
        font-size: 0.94em;
    }

    .nutri
    {
        min-width: 100pt;
        width: 100%;
        max-width: 400pt;
        border-spacing: 0;
        color: rgb(107, 107, 107);
        font-weight: 300;
        font-size: 0.9em;
    }

    .nutri td
    {
        border-bottom: 1pt solid rgb(243, 243, 243);
        padding: 7pt;
    }   

    .nutri .value
    {
        text-align: right;
    }

    ul
    {
        list-style-type: square;
        list-style-position: outside;
        color: rgb(117, 117, 117);
    }

    li
    {
        padding: 5pt;
    }

    #notes
    {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 80%;
        max-width: 400pt;
        margin: auto;
        padding-top: 10pt;
        padding-bottom: 10pt;
       
    }

    .note
    {
        margin: auto;
        display: grid;
        flex-direction: column;
        justify-self: flex-start;
        font-family: 'Montserrat';
        flex: 1;
    }


    .note .circle
    {
        overflow: hidden;
        display: flex;
        background: rgb(245, 245, 245);
        padding: 10pt;
        border-radius: 40pt;
        height: 30pt;
        width: 30pt;
        margin: auto;
    }

    .circle img
    {
        margin: auto;
        display: block;
    }



    .note .name
    {
        text-transform: uppercase;
        font-size: 8pt;
        letter-spacing: 0.5pt;
        text-align: center;
        flex: 1;
        margin-top: 10pt;
        padding: 3pt;
        color: rgb(107, 107, 107);
        padding-bottom: 25pt;
    }

    .meta
    {
        display: flex;
        margin: auto;
        margin-top: 40pt;
        width: fit-content;
        justify-content: space-evenly;
        border: 1pt solid rgb(214, 214, 214);
    }

    .meta .data
    {
        margin: 7pt;
        display: flex;
        flex-direction: column;
        text-align: center;
        min-width: 80pt;
        font-weight: 300;
    }

    .data .title
    {
        font-family: 'Montserrat';
        padding: 5pt;
        border-bottom: 1pt solid rgb(228, 228, 228);
        letter-spacing: 0.1em;
        font-size: 0.8em;
    }

    .data .value
    {
        padding: 5pt;
        font-size: 0.8em;
        
    }

    .map
    {
        margin: auto;
        width: 80%;
        max-width: 400pt;
    }

    :deep() .map .normal 
    {
        stroke: rgb(216, 216, 216);
        stroke-width: 1;
        transition: all ease-in-out 200ms;
    }

    :deep() .region
    {
        fill: #c2995c7a;
        stroke: none;
    }

    .location
    {
        font-family: 'Montserrat';
        font-size: 0.8em;
        font-weight: 400;
        color: #888888;
        letter-spacing: 0.08em;
        line-height: 1.3em;
        text-transform: uppercase;
        margin-top: -12pt;
        margin-left: 13pt;
    }


</style>
<script>

import InlineSvg from 'vue-inline-svg';

import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';


SwiperCore.use(Pagination);

import products from '../../public/data/products.json'

export default {
    components: {
      Swiper,
      SwiperSlide,
       InlineSvg,
    },
  data: function () {
    return {
      total:0,
      productsList: products,
      products: [],
      product:'',
      bagText: 'ADD TO BAG',
      quantity: '',
      showLoader: false,
      renderSwiper: '',
      stockLevel: { background: ''},
      measure: '',
      like:false,
      option:'',
      optionObj: '',
      initialOption: this.$route.params.option,
      locationString:'',
      map:''
    }
  },
  methods: {

    setIndicator()
    {
        if(this.product.stock_level == 3)
        {
            this.stockLevel.background = 'rgb(43, 192, 93)';
        }
        if(this.product.stock_level == 2)
        {
            this.stockLevel.background = 'rgb(255, 192, 76)';
        }
        if(this.product.stock_level == 1)
        {
            this.stockLevel.background = 'rgb(240, 69, 69)';
        }

    },
    updateBagText()
    {
        if(this.products?.find(x => x.productId === this.product.productId))
        {
            setTimeout(() => this.bagText = "UPDATE BAG", 300);
        }
    },
    updateMap()
    {
    for(var i = 0; i < this.product.city.length; i++)
        {
            var loc = this.product.city[i];
            document.getElementById(loc).classList.toggle('region');
        }
    },

    addToBag()
    {
        var Id = this.optionObj.id;
        var price = this.optionObj.price;
        var image = (this.product.type == "variety") ? '/Assets/product-previews/box-' + this.optionObj.value + '.png'  : this.product.images[0];
        var s = document.getElementById('select_quantity');

        if(!Id)
        {
            this.bagText = 'SELECT OPTION';
            setTimeout(() => 
            { 
                this.bagText = "ADD TO BAG",
                this.showLoader = false
            }, 
            1700);
            return;
        }

        if(this.products?.find(x => x.productId === Id))
        {
            this.showLoader = true;
            this.bagText = 'ADDING TO BAG';
            var foundIndex = this.products.findIndex(x => x.productId == Id);
            var found = this.products.find(x => x.productId == Id);
            var updated = {'productId' : Id, name: found.name, option:this.optionObj.value, image:image, desc: found.desc, link: '/product/' + this.product.id + '/' + this.optionObj.value, quantity : parseInt(s.value), price: found.price};
            this.products[foundIndex] = updated; 
            setTimeout(() => 
            {
                this.bagText = "ADDED TO BAG",
                this.showLoader = false
            }, 
            1700);      
        }
        else
        {
            this.showLoader = true;
            this.bagText = 'ADDING TO BAG';
            this.products.push({'productId' : Id, name: this.product.name, option:this.optionObj.value, image:image, desc: this.product.desc, link: '/product/' + this.product.id + '/' + this.optionObj.value, quantity : s.value, price: price});
            document.getElementById('dot').classList.add('showdot');
            setTimeout(() => 
            { 
                this.bagText = "ADDED TO BAG",
                this.showLoader = false
            }, 
            1700);
        }
            
        localStorage.setItem('products', JSON.stringify(this.products));

         //Get product from file given id
    },

    toggleColli(id)
    {
        document.getElementById(id).classList.toggle('open');
        //document.getElementById(id+'-shape').classList.toggle('close');
    }

  },
  beforeMount()
  {
    var id = this.$route.params.id
    this.product = this.productsList.find(x => x.id == id);

    this.optionObj = this.product.options.find(x => x.value == this.initialOption);
    
    this.map = require('../assets/gold-date.svg');

    this.measure = this.product.title.toLowerCase().replace(this.product.name.toLowerCase(), '');

    if(JSON.parse(localStorage.getItem('products')) != null)
    {
        this.products = JSON.parse(localStorage.getItem('products'));
    }

    if(this.products?.find(x => x.productId === this.product.productId))
    {
        //var found = this.products.find(x => x.productId == this.product.productId);
        //this.quantity = found.quantity;
    }

    this.renderSwiper = 1;
    
    document.title = this.product.title + ' ' + this.$route.params.option;

    

    this.locationString = (this.product.id == "variety") ? 'VARIOUS REGIONS' : this.product.city.join(',');
     
    

   

    this.setIndicator();

    
  },
  mounted()
  {
    if(this.product.disable)
    {
        var button = document.getElementById('addbutton');
        button.disabled = true;
        button.style.filter = "grayscale(100%)";
        this.bagText = "Out of stock";
    }
  }
  
}


</script>

<style>
.swiper-pagination-bullet-active
{
    background: gray !important;
}
</style>

<style scoped>

.swiper-container 
{
    display: flex !important;
    background-color: #FAFAFA;
    height: 100%;  
    flex: 1;  
    margin: auto;
    min-width: 300pt;
    transition: height ease-in-out 100ms;
}

.swiper-slide
{
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
    width: 100% !important;
    margin: auto !important;
}



.gallery-image
{
    padding-top: 30pt;
    padding-bottom: 30pt;
    max-height: 329px;
    width: auto !important;
    max-width: 240pt;
    height: auto !important;
}


</style>