<template>
 <div class="container"> 
    <div class="header">
        <div class="title">SHOP</div>
        <div style="margin-top:0pt" class="switcher">
            <div id="dates" @click="setCategory('dates')">
                <input type="radio" v-model="category" id="datesradio" value="0">
                <label for="datesradio" class="switch">
                    <div class="circle">
                        <img class="icon" style="height:28pt; margin-top:5pt" src="/Assets/products/icons/dates.svg" alt="">
                    </div>
                    <div class="title">DATES</div>
                </label>
            </div>
            <div id="coffee" @click="setCategory('coffee')">
                <input type="radio" v-model="category" id="coffeeradio" value="1">
                <label for="coffeeradio" class="switch">
                    <div class="circle">
                        <img class="icon" style="height:23.5pt; margin-top:10pt" src="/Assets/products/icons/coffee-cup.svg" alt="">
                    </div>
                    <div class="title">COFFEE</div>
                </label>
            </div>
            <div id="bundle" @click="setCategory('bundle')">
                <input type="radio" v-model="category" id="bundleradio" value="2">
                <label for="bundleradio" class="switch">
                    <div class="circle">
                        <img class="icon" style="height:23pt; margin-top:7pt" src="/Assets/products/icons/bundle.svg" alt="">
                    </div>
                    <div class="title">GIFTS &<br> BUNDLES</div>
                </label>
            </div>
        </div>
    </div>

    <swiper
         @swiper="onSwiper" 
         :noSwiping="true"
         :autoHeight="true"
         :initialSlide="startSlide">
        <swiper-slide class="swiper-wrapper swiper-no-swiping">
            <div class="content">
                <div class="sub-title">
                    300g
                </div>
                <div id="300g" class="products-grid">
                    <router-link to="/product/sukkari/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SUKKARI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SUKKARI</div>
                            <div class="desc">Sweet ripe dates</div>
                            <div class="price">£6</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/mabroom/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/MABROOM-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">MABROOM</div>
                            <div class="desc">Semi dry dates</div>
                            <div class="price">£8</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/ajwa/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/AJWA-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">AJWA</div>
                            <div class="desc">Sweet dry dates</div>
                            <div class="price">£9</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/segai/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SEGAI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SEGAI</div>
                            <div class="desc">Sweet chewy dates</div>
                            <div class="price">£7</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-300g.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX</div>
                            <div class="desc">Sukkari, Ajwa, Mabroom</div>
                            <div class="price">£9</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety2/300g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-300g.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX 2</div>
                            <div class="desc">Segai, Ajwa, Mabroom</div>
                            <div class="price">£10</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                </div>
                <div class="sub-title">
                    500g
                </div>
                <div id="500g" class="products-grid">
                <router-link to="/product/sukkari/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SUKKARI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SUKKARI</div>
                            <div class="desc">Sweet ripe dates</div>
                            <div class="price">£9</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/mabroom/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/MABROOM-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">MABROOM</div>
                            <div class="desc">Semi dry dates</div>
                            <div class="price">£13</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/ajwa/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/AJWA-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">AJWA</div>
                            <div class="desc">Sweet dry dates</div>
                            <div class="price">£15</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/segai/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SEGAI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SEGAI</div>
                            <div class="desc">Sweet chewy dates</div>
                            <div class="price">£11</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-500g.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX</div>
                            <div class="desc">Sukkari, Ajwa, Mabroom</div>
                            <div class="price">£15</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety2/500g" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-500g.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX 2</div>
                            <div class="desc">Segai, Ajwa, Mabroom</div>
                            <div class="price">£16</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                </div>
                <div class="sub-title">
                    1kg
                </div>
                <div id="1kg" class="products-grid">
                    <router-link to="/product/sukkari/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SUKKARI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SUKKARI</div>
                            <div class="desc">Sweet ripe dates</div>
                            <div class="price">£14</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                    <router-link to="/product/mabroom/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/MABROOM-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">MABROOM</div>
                            <div class="desc">Semi dry dates</div>
                            <div class="price">£17</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                    <router-link to="/product/ajwa/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/AJWA-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">AJWA</div>
                            <div class="desc">Sweet dry dates</div>
                            <div class="price">£23</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                    <router-link to="/product/segai/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/SEGAI-bev-removebg-preview.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">SEGAI</div>
                            <div class="desc">Sweet chewy dates</div>
                            <div class="price">£15</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-1kg.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX</div>
                            <div class="desc">Sukkari, Ajwa, Mabroom</div>
                            <div class="price">£21</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                    <router-link to="/product/variety2/1kg" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/box-1kg.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">VARIETY BOX 2</div>
                            <div class="desc">Segai, Ajwa, Mabroom</div>
                            <div class="price">£22</div>
                            <div class="more">1kg</div>
                        </div>
                    </router-link>
                </div>     
            </div>
        </swiper-slide>
        <swiper-slide class="swiper-wrapper swiper-no-swiping">
            <div class="content">
                <div class="sub-title">
                    250g
                </div>
                <div id="300g" class="products-grid">
                    <router-link to="/product/gahwa-yemeni/Ground" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/yemeni.png" style="transform:scale(1.25);" alt="">
                        </div>
                        <div class="info">
                            <div class="title">GAHWA BLEND</div>
                            <div class="desc">Sanaa, Yemen</div>
                            <div class="price">£13</div>
                            <div class="more">250g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/gahwa-harari/Ground" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/harari.png"  alt="">
                        </div>
                        <div class="info">
                            <div class="title">GAHWA BLEND</div>
                            <div class="desc">Harar, Ethiopia</div>
                            <div class="price">£10</div>
                            <div class="more">250g</div>
                        </div>
                    </router-link>
                </div>   
            </div>
        </swiper-slide>
        <swiper-slide class="swiper-wrapper swiper-no-swiping">
            <div class="content">
                <div class="sub-title">
                   GIFTS
                </div>
                <div id="all-bundles" class="products-grid">
                    <router-link to="/product/gahwa-giftbox-yemeni/Sukkari" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/gahwabundle300.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">GAHWA & DATES GIFT BOX</div>
                            <div class="desc">Sanaa, Yemen</div>
                            <div class="price">£20</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/gahwa-giftbox-harari/Sukkari" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/gahwabundle300.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">GAHWA & DATES GIFT BOX 2</div>
                            <div class="desc">Harar, Ethiopia</div>
                            <div class="price">£17</div>
                            <div class="more">300g</div>
                        </div>
                    </router-link>
                    <router-link to="/product/gahwa-twin-pack/Ground" class="product">
                        <div class="preview">
                            <img src="/Assets/product-previews/twin-pack-gahwa.png" alt="">
                        </div>
                        <div class="info">
                            <div class="title">GAHWA GIFT BOX</div>
                            <div class="desc">Yemen & Ethiopia</div>
                            <div class="price">£25</div>
                            <div class="more">500g</div>
                        </div>
                    </router-link>
                </div>
            </div>
        </swiper-slide>
    </swiper>
    
</div>
</template>

<script>

import SwiperCore, { Pagination, Navigation } from 'swiper';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

SwiperCore.use(Navigation, Pagination);


export default {
     components: {
      Swiper,
      SwiperSlide
    },
    data() 
    {
        return {
            category:'',
            swiper:null,
            option: '',
            startSlide:0
        }
    },

    created()
    {
        this.option = this.$route.params.option;

        if(this.option)
        {
            if(this.option == 'dates')
            {
                this.startSlide = 0;
            }
            else if(this.option == 'coffee')
            {
                this.startSlide = 1;
            }
            else if(this.option == 'bundle')
            {
                this.startSlide = 2;
            }
        }
        
    },

    mounted()
    {
        document.title = "Shop - All Products";
        
        this.option = this.$route.params.option;

        if(this.option)
        {
            document.getElementById(this.option + 'radio').checked = true;
        }
        else
        {
            document.getElementById('datesradio').checked = true;
        }

    },
    methods:
    {
        setCategory(cat)
        {
            if(cat == 'dates')
            {
                this.swiper.slideTo(0);
            }
            else if(cat == 'coffee')
            {
                this.swiper.slideTo(1);
            }
            else if(cat == 'bundle')
            {
                this.swiper.slideTo(2);
            }
        },
        onSwiper(swiper) {
            this.swiper = swiper;
        },
    }
}
</script>

<style scoped>


#app
{
        
}


.swiper-container   
{
    position: sticky;
    top: 36pt;
    background: white;
    padding-bottom: 20pt;
}

.header
{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-blend-mode: screen;
    width: 100%;
    padding-top: 30pt;
    filter: saturate(1.3);
    padding-bottom: 20pt;
}

 .header .title
{
    font-family: 'Montserrat';
    font-size: 1.8em;
    font-weight: 500;
    letter-spacing: 0.09em;
    text-align: center;
}

.switcher
{
    display: flex;
    margin: auto;
    width: fit-content;
    justify-content: space-evenly;
}

.switch
{
    flex: 1;
    padding: 5pt;
    min-width: 75pt;
    filter: grayscale(1);
    color: grey;
    cursor: pointer;
    transition: ease-in-out all 200ms;
    display: flex;
    flex-direction: column;
}

.switch .circle
{
    height: 40pt;
    width: 40pt;
    border-radius: 80pt;
    margin: auto;
    background: whitesmoke;
    border: 2.5pt solid rgba(255, 255, 255, 0);
    display: flex;
    padding: 5pt;
    z-index: -1;
    transition: ease-in-out all 200ms;
    
}

.switch .circle .icon
{
    margin: auto;
    height: 30pt;
    display: block;
}

.switch .title
{
    font-family: 'Montserrat';
    text-align: center;
    height:fit-content;
    padding: 10pt;
    font-size: 0.7em;
    z-index: 9;
}

input[type="radio"]
{
    appearance: none;
    visibility: hidden;
}


input[type="radio"]:checked + .switch
{
    filter: unset;
    color: #C29A5C;
    transition: ease-in-out all 200ms;
}

input[type="radio"]:checked + .switch .circle
{
    border: 2.5pt solid #F0BF73;
    background: white;
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
transition: ease-in-out all 200ms;
}





.header .desc   
{
    padding-top: 15pt;
    width: 80%;
    max-width: 350pt;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
}

.sub-title
{
    position: sticky;
    top: 0;
    font-family: 'Montserrat';
    font-size: 1em;
    font-weight: 50;
    letter-spacing: 0.06em;
    background-color: white;
    color: rgb(80, 80, 80);
    z-index: 99;
    border-bottom: 1pt solid rgb(240, 240, 240);    
    padding: 10pt;
    text-transform: uppercase;

}





.container
{
    max-width: 900pt;
    margin: auto;
    margin-top: 0pt;
    padding-bottom: 10pt;
    scroll-behavior: smooth;
}

.content
{
    position: relative;
    width: 100%;
    height: 100%;
}

.products-grid
{
    position: relative;
    margin-bottom: 30pt;
    display: grid;
    grid-template-columns: 1fr 1fr;    
    border-left: 1pt solid rgb(240, 240, 240);
    background: rgb(251, 251, 251);
}

@media only screen and (min-width: 660px) {
    .products-grid
    {
        grid-template-columns: 1fr 1fr 1fr ;
    }
} 

@media only screen and (min-width: 900px) {
    .products-grid
    {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
} 

.product 
{
    position: relative;
    background-color: white;
    min-width: 120pt;
    max-width: 400pt;
    box-sizing: border-box;
    border-right: 1pt solid rgb(240, 240, 240);
    border-bottom: 1pt solid rgb(240, 240, 240);
    transition: all ease-in-out 2000ms;
}

.product:active {
    transform: scale(1.2);
    z-index: 9999;
    border-radius: 3pt;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    max-height: unset;
    top: 0;
}

.product:last-child
{
    
}

.product .preview
{
    display: flex;
    padding: 10pt;
    min-height: 130pt;
}

.product .preview img
{
    max-height: 100pt;
    max-width: 85%;
    margin: auto;
}

.product .info
{
    background-color: white;
    padding: 9pt;
    padding-left: 12pt;
    padding-bottom: 9pt;
}

.product .info .title
{
    font-family: 'Montserrat';
    font-size: 1em;
    font-weight: 550;
    letter-spacing: 0.06em;
}
.product .info .desc
{
    font-family: 'Montserrat';
    margin-top: 3pt;
    font-size: 0.75em;
    color: #8D8D8D;
    font-weight: 300;
}

.product .info .price   
{
    margin-top: 11pt;
    font-size: 1em;
    font-weight: 500;
}

.product .info .more
{
    position: absolute;
    margin-top: 5pt;
    font-size: 0.7em;
    color: #8D8D8D;
    bottom: 11pt;
    right: 13pt;
    text-align: right;
}

#soon
{
    padding: 25pt;
    font-family: 'Montserrat';
    font-size: 2em;
    text-align: center;
    color: grey;
}


</style>
<style>

</style>