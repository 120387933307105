<template>
  <div class="container"> 
                <div class="header">
                    <div class="title">CONTACT US</div>
                    <div class="desc">
                        Whether you're looking to buy dates for the gym or can't decide what to take for grandma, we're here to help. 
                    </div>
                </div>

                <div class="ways-grid">
                    <a class="way" href="mailto:info@tamrdate.co">
                        <img src="" alt="">
                        <div class="info">
                            <div class="title">EMAIL</div>
                            <div class="desc">info@tamrdate.co</div>
                        </div>
                    </a>
                    <a class="way" href="https://wa.me/+447493314488">
                        <img src="" alt="">
                        <div class="info">
                            <div class="title">WHATSAPP</div>
                            <div class="desc">Chat now</div>
                        </div>
                    </a>
                </div>
                
            </div>  
</template>

<script>
export default {
    mounted()
    {
        document.title = "Contact Us"
    }
}
</script>

<style scoped>


.container
{
    max-width: 900pt;
    margin: auto;
    margin-top: 50pt;
    overflow: hidden;
}

.header
{
    padding: 11pt;
}

 .header .title
{
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
}

.header .desc   
{
    padding-top: 25pt;
    max-width: 500pt;
    width: 90%;
    font-size: 1em;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
    color: rgb(86, 86, 86);
}



.ways-grid 
{
    margin-top: 50pt;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}
.ways-grid .way
{
    width: 85%;
    min-width: 200pt;
    max-width: 500pt;
    padding: 5pt;
    margin-bottom: 8pt;
    border: 1.2pt solid #e2e2e2;
    border-radius: 3pt;
}

.way .info .title
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 0.75em;
    letter-spacing: 0.06em;
    color: #999999;
    padding-left: 5pt;
    padding-top: 3;

}

.way .info .desc
{
    font-size: 1em;
    padding: 5pt;
    padding-left: 5.5pt;
    font-weight: 400;
    color: rgb(40, 40, 40);
}

</style>