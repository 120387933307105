<template>
    <div class="container">  
        <div class="title">SEE YOU SOON</div> 
        <div class="desc">
            Thank you for shopping with Tamr Date Co., your order has been placed successfully. 
            We'll update you as your order makes way, 
            feel free to contact our team if you have any queries.
        </div>
        
        <a href="./index.html">
        <div class="to-home">← BACK TO HOME</div>
        </a>

    </div>
</template>

<style scoped>


.container
{
    max-width: 700pt;
    margin: auto;
    margin-top: 67pt;
    overflow: hidden;
}

.title
{
    font-family: 'Montserrat';
    font-size: 1.5em;
    font-weight: 500;
    letter-spacing: 0.06em;
    text-align: center;
}

.desc   
{
    padding-top: 15pt;
    width: 90%;
    line-height: 1.5em;
    text-align: center;
    margin: auto;
    color: rgb(86, 86, 86);
}

.to-home
{
    text-align: center;
    padding: 40pt;
    font-weight: 500;
    text-decoration: underline rgb(197, 197, 197);
}


</style>